import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, FormControlLabel, Checkbox } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const RemoveCompetitorButton = ({ onClick, competitorName = "Competitor" }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [hasConfirmed, setHasConfirmed] = useState(false);
    const [hasShownPopup, setHasShownPopup] = useState(false);
    const [hideMessageInFuture, setHideMessageInFuture] = useState(true);

    useEffect(() => {
        const shownPopup = localStorage.getItem('shownHidePopup');
        if (shownPopup) {
            setHasShownPopup(true);
        }
    }, []);

    const handleRemoveClick = () => {
        if (!hasConfirmed && !hasShownPopup) {
            setOpenDialog(true);
        } else {
            onClick();
        }
    };

    const handleConfirmRemove = () => {
        setHasConfirmed(true);
        setOpenDialog(false);
        if (hideMessageInFuture) {
            localStorage.setItem('shownHidePopup', 'true');
        }
        onClick();
    };

    const handleCancelRemove = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Tooltip title="This will remove this game from your ranking pool." arrow>
                <IconButton 
                    sx={{ 
                        position: 'absolute', 
                        top: 8, 
                        right: 8, 
                        bgcolor: 'rgba(255, 255, 255, 0.8)', 
                        border: '2px solid black', 
                        borderRadius: '50%', 
                        '&:hover': { bgcolor: 'rgba(255, 255, 255, 1)' } 
                    }} 
                    onClick={handleRemoveClick}
                >
                    <VisibilityOffIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={openDialog}
                onClose={handleCancelRemove}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Remove Competitor from Ranking Pool`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Are you sure you want to remove '${competitorName}' from your ranking pool? It will not show up as a future match and will be unseeded in your Tier List. ${competitorName} will not be required to complete your Tier List but will continue to show in the Global Rankings.`}
                    </DialogContentText>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hideMessageInFuture}
                                onChange={(e) => setHideMessageInFuture(e.target.checked)}
                                name="hideMessageInFuture"
                                color="primary"
                            />
                        }
                        label="Hide message in future"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelRemove} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmRemove} color="primary" autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RemoveCompetitorButton;
