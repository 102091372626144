import React from 'react';
import TabbedPageLayout from '../components/TabbedPageLayout';
import TopCompetitorsTable from '../components/TopCompetitorsTable';
import { Card, Typography, List, ListItem, Box, CardMedia } from '@mui/material';
import SEO from '../components/SEO';
import { Public } from '@mui/icons-material';

const HomePage = () => {

  const SiteInstructions = () => {
    return (
        <Card sx={{ p: 2, bgcolor: '#f5f5f5', boxShadow: 3, borderRadius: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', p: 2 }}>
              <CardMedia
                component="img"
                sx={{
                  maxWidth: { xs: '100%', sm: '25%' },
                  height: 'auto',
                  borderRadius: '16px',
                  border: '2px solid #3f51b5',
                  boxShadow: 2,
                }}
                image={"/images/whichisthebest.png"}
                alt={`Image`}
              />

            <Box
              sx={{
                mt: { xs: 2, sm: 0 },
                ml: { sm: 3 },
                flex: 1,
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h4" component="div" gutterBottom sx={{ fontWeight: 'bold' }}>
              Welcome to WhichIsThe.BEST for Steam Games!
              </Typography>
              <Typography variant="body1" component="div" gutterBottom sx={{ color: '#555', mb: 2 }}>
                Discover tier lists for games available on the Steam store and find the best games across various genres. Genres are derived from Steam tags associated with games by the Steam Community. Join us in deciding which games deserve the top spots!
              </Typography>
              <Typography variant="h6" component="div" gutterBottom sx={{ fontWeight: 'bold' }}>
                Instructions:
              </Typography>
              <List sx={{ bgcolor: '#e3f2fd', borderRadius: 1, p: 1 }}>
                <ListItem>
                  <Typography variant="body1">1) Login with Steam.</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">2) Select Rank Games from the menu to complete your tier lists.</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">3) Navigate to the Tier Lists page to view global and personalised tier lists.</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">4) Share interesting matchups on social media and try to increase your score!</Typography>
                </ListItem>
              </List>

            </Box>
          </Box>
        </Card>

    );
  };

  const tabs = [
    {
      label: 'WHICH IS BEST',
      icon: <Public />,
      title: `Global Best ${process.env.REACT_APP_COMPETITOR_DESCRIPTION}`,
      subtitle: 'List of the best games in the world as ranked by all users of the site.',
      content: (

          <TopCompetitorsTable />
      ),
    },
  ];

  return (
    <>
      <SEO 
        title="WhichIsThe.BEST" 
        description="Discover the best games in the world as ranked by all users of the site. Compare your Steam games head-to-head based on different categories like gameplay, story, or graphics. Simply log in with Steam to import your library and start rating!" 
        image="/images/whichisthebest.png"
        keywords="Steam, games, tier list, ranking, best, compare, community, social media"
      />
      <TabbedPageLayout
        customTopCard={<SiteInstructions />}
        tabs={tabs}
      />
    </>
  );
};

export default HomePage;
