import React, { useState } from 'react';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Typography,
  TextField,
  Box,
  InputAdornment,
  Tooltip
} from '@mui/material';
import { Search, ArrowUpward, ArrowDownward,  VisibilityOff } from '@mui/icons-material'; // Import MUI icons
import { useMediaQuery, useTheme } from '@mui/material';
import ImageWithPlaceholderOrEmoji from './ImageOrPlaceholder';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

export default function DataImageList({ itemData, linkTo = "/competitor/", itemDescriptor = "Steam Games", metricDescriptor = "minutes played.", showSearch = true, metricField = "relevancy_metric", sortDirection = "asc", trendField, hiddenField }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [searchQuery, setSearchQuery] = useState(''); // State to hold the search input

  const getCols = () => {
    if (isXs) return 3;
    if (isSm && !isMd) return 4;
    if (isMd && !isLg) return 5;
    if (isLg) return 6;
    return 2;
  };

  // Sort itemData based on metricField and sortDirection
  const sortedData = [...itemData].sort((a, b) => {
    if (sortDirection === "asc") {
      return a[metricField] - b[metricField];
    } else {
      return b[metricField] - a[metricField];
    }
  });

  // Filter sortedData based on searchQuery
  const filteredData = sortedData.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box>
      {showSearch && (
        <TextField
          label={`Search for your ${itemDescriptor}`}
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} // Update the search query
          size="small" // Make the search field small
          sx={{ m: 2, width: "80%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}

      {/* Image list */}
      <ImageList cols={getCols()} gap={4} sx={{ mt: 0, mb: 0 }}>
        {Array.isArray(filteredData) && filteredData.length > 0 ? (
          filteredData.map((item) => (
            
            <ImageListItem key={item.corelation_id} sx={{ height: '200px', overflow: 'hidden' }}>
              <Tooltip title={item.name} arrow>
                <Link to={`/${linkTo}${item.corelation_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Paper sx={{  borderRadius: '8px', m: 0.5, position: 'relative' }}>
                    <ImageWithPlaceholderOrEmoji
                      src={item.large_image_url}
                      alt={item.name}
                      emoji={item.criterion_emoji}
                      styles={{
                        transition_1_color: item.transition_color_1 || '#4a4a4a',
                        transition_2_color: item.transition_color_2 || '#141414',
                        font_color: item.font_color || '#FFFFFF'
                      }}
                      sx={{ height: '100%', objectFit: 'cover' }}
                    />
                    {hiddenField && (item[hiddenField] !== 0) && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(255, 255, 255, 0.5)',
                          display: 'flex',
                          alignItems: 'top',
                          justifyContent: 'center'
                        }}
                      >
                        <VisibilityOff sx={{ color: 'rgba(0, 0, 0, 0.8)', fontSize: '4rem' }} />
                      </Box>
                    )}

                    <ImageListItemBar
                      title={
                        <Typography
                          variant="body2"
                          sx={{
                            whiteSpace: 'normal',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical'
                          }}
                        >
                          {item.name}
                        </Typography>
                      }
                      subtitle={
                        <span>
                          {trendField && item[trendField] !== undefined && (
                            item[trendField] > 0 ? (
                              <ArrowUpward sx={{ verticalAlign: 'middle', color: 'green', fontSize: 'small' }} />
                            ) : item[trendField] < 0 ? (
                              <ArrowDownward sx={{ verticalAlign: 'middle', color: 'red', fontSize: 'small' }} />
                            ) : null
                          )}
                          {item[metricField]} {metricDescriptor}
                        </span>
                      }
                      position="below"
                    />
                  </Paper>
                </Link>
              </Tooltip>
            </ImageListItem>
          ))
        ) : (
          <Typography variant="body1">No {itemDescriptor} found.</Typography>
        )}
      </ImageList>
    </Box>
  );
}
