import React, { useState } from 'react';
import domtoimage from 'dom-to-image-more';
import { Chip, Modal, Box, CircularProgress, IconButton, Alert, Button } from '@mui/material';
import { Image, Close, ErrorOutline, CheckCircle, Download } from '@mui/icons-material';
import QRCode from 'qrcode';

const DownloadImageChip = ({ elementId, tierListName, criterionName = "Unknown", url, qrCodeTitle = "Disagree? Rank to Update!" }) => {
  const [loading, setLoading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [error, setError] = useState('');
  const [timeoutError, setTimeoutError] = useState(false);
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState('');
  const [generatedImage, setGeneratedImage] = useState(''); // New state for generated image

  const TIMEOUT_DURATION = 15000; // 15 seconds timeout
  const BACKGROUND_COLOR = '#f9f9f9';
  const MIN_WIDTH = 1000;
  const QR_SIZE = 225;
  const TITLE_FONT_SIZE = 32;
  const WATERMARK_FONT_SIZE = 20;
  const QR_CODE_TITLE_FONT_SIZE = 30;
  const TITLE_Y_POSITION = 40;
  const IMAGE_Y_POSITION = 50;
  const WATERMARK_Y_OFFSET = 60;
  const QR_CODE_Y_OFFSET = 60;
  const QR_CODE_X_OFFSET = 20;
  const WATERMARK_X_OFFSET = 20;
  const MODAL_MAX_WIDTH = '600vw';
  const MODAL_MAX_HEIGHT = '600vh';
  const IMAGE_MAX_HEIGHT = '500px';

  const handleDownloadImage = async () => {
    const element = document.getElementById(elementId);
    if (!element) {
      console.error(`Element with ID ${elementId} not found.`);
      return;
    }

    // Set background color to #f9f9f9
    element.style.backgroundColor = BACKGROUND_COLOR;

    setLoading(true);
    setDownloaded(false);
    setError('');
    setTimeoutError(false); // Reset timeout error state

    const timeout = setTimeout(() => {
      setTimeoutError(true);
      setLoading(false);
    }, TIMEOUT_DURATION);

    try {
      console.log('Starting image generation...');
      const dataUrl = await domtoimage.toJpeg(element, {
        cacheBust: true,
        useCORS: false,
      });
      clearTimeout(timeout);
      console.log('Image generated successfully.');

      const qrCodeOptions = {
        color: {
          dark: '#000000',  // QR code color
          light: '#0000'    // Transparent background
        }
      };

      if (url) {
        const qrCodeDataUrl = await QRCode.toDataURL(url, qrCodeOptions);
        setQrCodeDataUrl(qrCodeDataUrl);
      }

      addWatermarkAndDownload(dataUrl);
      setGeneratedImage(dataUrl); // Set the generated image data URL
    } catch (error) {
      clearTimeout(timeout);
      console.error("Error generating image:", error);
      setError('Error generating image. Please try again.');
      setLoading(false);
    }
  };

  const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
    const words = text.split(' ');
    let line = '';
    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        ctx.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
      } else {
        line = testLine;
      }
    }
    ctx.fillText(line, x, y);
  };

  const addWatermarkAndDownload = async (dataUrl) => {
    const img = new window.Image();
    img.src = dataUrl;
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const scaleFactor = Math.max(MIN_WIDTH / img.width, 1); // Calculate scale factor to maintain aspect ratio

      canvas.width = img.width * scaleFactor;
      canvas.height = (img.height * scaleFactor) + QR_SIZE + WATERMARK_Y_OFFSET; // Extra space for title and watermark
      const watermarkdy = canvas.height - WATERMARK_Y_OFFSET;

      // Set background color to #f9f9f9
      ctx.fillStyle = BACKGROUND_COLOR;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(img, 0, IMAGE_Y_POSITION, img.width * scaleFactor, img.height * scaleFactor); // Scale image

      // Add title
      ctx.font = `bold ${TITLE_FONT_SIZE}px Arial`;
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      wrapText(ctx, `'${tierListName}' Tier List`, canvas.width / 2, TITLE_Y_POSITION, canvas.width - 20, TITLE_FONT_SIZE + 5);

      // Add watermark
      ctx.font = `${WATERMARK_FONT_SIZE}px Arial`;
      ctx.fillStyle = 'black';
      ctx.textAlign = 'left';
      const currentDate = new Date().toUTCString(); // Get current UTC date
      const watermarkText = `Image generated by steamgames.whichisthe.best from ${process.env.REACT_APP_COMPETITOR_DESCRIPTION} with ${process.env.REACT_APP_CRITERION_DESCRIPTOR} '${criterionName}' on ${currentDate}. Current Tier List may differ from this image, see QR code for current list.`;

      wrapText(ctx, watermarkText, WATERMARK_X_OFFSET, watermarkdy + 10, img.width - QR_SIZE - 50, WATERMARK_FONT_SIZE);

      // Add QR code if URL is provided
      if (url) {
        const qrCodeOptions = {
          color: {
            dark: '#000000',  // QR code color
            light: '#0000'    // Transparent background
          }
        };
        const qrCodeDataUrl = await QRCode.toDataURL(url, qrCodeOptions);
        const qrImg = new window.Image();
        qrImg.src = qrCodeDataUrl;
        qrImg.onload = () => {
          const qrdx = canvas.width - QR_SIZE - QR_CODE_X_OFFSET;

          ctx.drawImage(qrImg, qrdx, canvas.height - QR_SIZE - QR_CODE_Y_OFFSET, QR_SIZE, QR_SIZE);

          // Add "Live view" text below the QR code
          ctx.font = `bold ${QR_CODE_TITLE_FONT_SIZE}px Arial`;
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          wrapText(ctx, qrCodeTitle, qrdx + (QR_SIZE / 2), watermarkdy + 10, QR_SIZE + 20, QR_CODE_TITLE_FONT_SIZE + 5);

          const finalDataUrl = canvas.toDataURL('image/jpeg');
          console.log('Title, watermark, and QR code added successfully.');

          setLoading(false);
          setDownloaded(true);
          setGeneratedImage(finalDataUrl); // Set the final generated image data URL
        };
      } else {
        const finalDataUrl = canvas.toDataURL('image/jpeg');
        console.log('Title and watermark added successfully.');

        setLoading(false);
        setDownloaded(true);
        setGeneratedImage(finalDataUrl); // Set the final generated image data URL
      }
    };
  };

  const triggerDownload = (dataUrl) => {
    const link = document.createElement('a');
    const fileName = `${criterionName}-wit.best.jpeg`;
    link.download = fileName;
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    console.log('Image download triggered.');
  };

  return (
    <>
      <Chip
        icon={<Image />}
        label="Generate Image"
        onClick={handleDownloadImage}
        sx={{
          backgroundColor: 'lightgray',
          '&:hover': { backgroundColor: 'darkgray' },
          textDecoration: 'none',
          margin: '0 8px',
        }}
        clickable
      />
      <Modal open={loading}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box
            bgcolor="white"
            p={3}
            borderRadius={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <CircularProgress />
            <Box mt={2}>Generating image</Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={downloaded} onClose={() => setDownloaded(false)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          onClick={() => setDownloaded(false)}
        >
          <Box
            bgcolor="white"
            p={3}
            borderRadius={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
            maxWidth={MODAL_MAX_WIDTH}
            maxHeight={MODAL_MAX_HEIGHT}
            onClick={(e) => e.stopPropagation()}
          >
            <IconButton
              onClick={() => setDownloaded(false)}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              <Close />
            </IconButton>
            <CheckCircle color="success" sx={{ fontSize: 40 }} />
            <Box mt={2}>Image "{criterionName}-wit.best.png" has been generated.</Box>
            {generatedImage && (
              <Box mt={2}>
                <img src={generatedImage} alt="Generated" style={{ maxWidth: '100%', maxHeight: IMAGE_MAX_HEIGHT }} />
                <Box mt={2}>
                  <Button
                    variant="contained"
                    startIcon={<Download />}
                    onClick={() => triggerDownload(generatedImage)}
                  >
                    Download
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
      <Modal open={timeoutError} onClose={() => setTimeoutError(false)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          onClick={() => setTimeoutError(false)}
        >
          <Box
            bgcolor="white"
            p={3}
            borderRadius={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
            onClick={(e) => e.stopPropagation()}
          >
            <IconButton
              onClick={() => setTimeoutError(false)}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              <Close />
            </IconButton>
            <ErrorOutline color="error" sx={{ fontSize: 40 }} />
            <Box mt={2}>Image generation timed out. Please try again.</Box>
          </Box>
        </Box>
      </Modal>
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
    </>
  );
};

export default DownloadImageChip;
