import React from 'react';
import FaceOffDataTable from './FaceOffDataTable';

const TierListStatisticsTable = ({ criterion_name, leaderboardId }) => {
  const headCells = [
    { id: 'large_image_url', label: 'Image', type: 'image', numeric: false },
    { id: 'ranking', label: 'Ranking', type: 'trend', numeric: true, properties: {trendField:'trending'} },
    { id: 'tier', label: 'Tier', type: 'tier', numeric: false },
    {
      id: 'name',
      label: 'Game',
      type: 'link',
      hideMobile: true,
      properties: { page_name: 'competitor/', link_data: 'corelation_id' },
    },
    { id: 'ranked_count', label: '# Times Ranked', type: 'number', hideMobile: true },
    { id: 'percentile', label: 'Percentile Score', type: 'number', hideMobile: true },
    { id: 'highest_ranking', label: 'Highest Ranking', type: 'number', hideMobile: true },
    { id: 'highest_elo', label: 'Highest Elo', type: 'number', hideMobile: true },
    {
      id: 'faceoff',
      label: '',
      type: 'faceoff',
      numeric: false,
      properties: { criterion: 'criterion', competitor: 'corelation_id' },
    },
    {
      id: 'share',
      label: '',
      type: 'share',
      numeric: false,
      properties: {
        primaryCompetitorId: 'corelation_id',
        primaryCompetitorName: 'name',
        primaryCompetitorImage: 'large_image_url',
        criterion: 'criterion',
      },
    },
  ];

  const endpoint = `${process.env.REACT_APP_SERVER_URL}/GetLeaderboardRankings?criterion=${criterion_name}&seeded=1${leaderboardId ? `&leaderboard_id=${leaderboardId}` : ''}`;

  return (
    <FaceOffDataTable
      endpoint={endpoint}
      headCells={headCells}
      defaultSortOrder="asc"
      defaultSortField="ranking"
    />
  );
};

export default TierListStatisticsTable;
