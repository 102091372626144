import React from 'react';
import TabbedPageLayout from '../components/TabbedPageLayout';
import ProfileCard from '../components/ProfileCard';
import Login from './Login';
import { useUser } from '../UserContext';
import TopCompetitorsTable from '../components/TopCompetitorsTable';
import { Star } from '@mui/icons-material';

const Profile = () => {
  const { user } = useUser();

  if (!user) {
    return <Login />;
  }

  const tabs = [
    {
      label: 'My TOP RATED GAMES',
      icon: <Star />,
      title: 'My Top Rated Games',
      subtitle: 'Games that have been rated by you.',
      content: <TopCompetitorsTable profileId={user.id} />,
    },
    /*
    {
      label: 'Unranked Games',
      title: 'Almost Seeded Games',
      subtitle: 'Games are only included on leaderboards once they have been ranked a number of times.',
      content: (
        <FaceOffDataTable
          endpoint={`${process.env.REACT_APP_SERVER_URL}/GetMyUnseededCompetitors`}
          headCells={[
            {
              id: 'large_image_url',
              label: 'Image',
              type: 'image',
              numeric: false,
              properties: { isImage: true },
            },
            {
              id: 'name',
              label: 'Game',
              type: 'link',
              hideMobile: true,
              properties: { page_name: 'competitor/', link_data: 'corelation_id' },
            },
            {
              id: 'leaderboard_name',
              label: 'Leaderboard',
              type: 'link',
              properties: { defaultEmoji: '🏆', page_name: 'Leaderboard/id/', link_data: 'leaderboard' },
            },
            { id: 'ranked_count', label: 'Ranked Count', type: 'text', numeric: true },
            {
              id: 'faceoff',
              label: '',
              type: 'faceoff',
              numeric: false,
              properties: { criterion: 'criterion', competitor: 'competitor_id' },
            },
            {
              id: 'share',
              label: '',
              type: 'share',
              numeric: false,
              properties: {
                primaryCompetitorId: 'competitor_id',
                primaryCompetitorName: 'name',
                primaryCompetitorImage: 'large_image_url',
                criterion: 'criterion',
              },
            },
          ]}
          defaultSortField="ranked_count"
          defaultSortOrder="desc"
        />
      ),
    },*/
  ];

  return (
    <TabbedPageLayout
      customTopCard={<ProfileCard user={user} />}
      tabs={tabs}
    />
  );
};

export default Profile;
