import React from 'react';
import { Typography, Box, Card } from '@mui/material';
import TabbedPageLayout from '../components/TabbedPageLayout';
import { Info, Star, Help } from '@mui/icons-material';

const AboutPage = () => {
  document.title = 'About';

  const topCardProps = {
    image: null, // No image for the About page
    title: 'About',
    body: ['For any general questions, please contact us at info [at] whichisthe.best'],
  };

  const tabs = [
    {
      label: 'Key Features',
      icon: <Star />,
      content: (
        <>
          <Typography variant="h5" gutterBottom sx={{ textAlign: 'left' }}>
            Key Features
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ textAlign: 'left' }}>
            StameGames.WhichIsThe.BEST offers the following key features to enhance your gaming experience:
          </Typography>
          <Box component="ul" sx={{ textAlign: 'left' }}>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Generate genre-based tier lists for your games by ranking them against each other.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Compare your tier lists with others and global rankings.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Share your tier lists and matchups on social media.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                AI-generated genre themes and descriptions.
              </Typography>
            </li>
          </Box>
          <Typography variant="body1" gutterBottom sx={{ textAlign: 'left' }}>
            Target audience:
          </Typography>
          <Box component="ul" sx={{ textAlign: 'left' }}>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Content generators who want to share their tier lists and matchups.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Tier List enthusiasts who want to compare their rankings with others.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Gamers looking for new games to play in specific genres.
              </Typography>
            </li>
          </Box>
        </>
      ),
    },
    {
      label: 'Ranking System',
      icon: <Info />,
      content: (
        <>
          <Typography variant="h5" gutterBottom sx={{ textAlign: 'left' }}>
            Ranking System
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ textAlign: 'left' }}>
            WhichIsThe.BEST uses the bespoke FaceOff ranking system, which seeks to address the shortcomings of legacy
            ranking systems:
          </Typography>
          <Box component="ul" sx={{ textAlign: 'left' }}>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Ranking is often arbitrary and subject to bias. On this site, you rank things against each other and the
                algorithm will decide the game’s score.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Things change quickly, what is great one day can be terrible the next. Ranking on this site is fluid and
                can change often to reflect the changing world we live in.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Comparing one thing against another can be like comparing apples and oranges. In the FaceOff ranking
                system, everything is categorized, so you only rate apples to apples.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                People often only rate things when they are happy or upset. This system aims to make rating fun.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Game tag data comes from Steam and is weighted by community votes. The community often makes strange
                decisions around these.
              </Typography>
            </li>
          </Box>
        </>
      ),
    },
    {
      label: 'How to Rank Games',
      icon: <Help />,
      content: (
          <>
          <Typography variant="h5" gutterBottom sx={{ textAlign: 'left' }}>
            How to Rank Games
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'left' }}>
            You must log in to rank games, and you will only be able to rank games in your Steam Library. Your privacy
            setting must allow for your games to be shared publicly for these to be imported into the system. For
            improved results, allow your playtime to be shared, which will give better matches based on your playtime.
            You can always change your privacy settings once your games are synced, noting this may take a few minutes.
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'left' }}>
            There are 4 ways to rank games:
          </Typography>
          <Box component="ul" sx={{ textAlign: 'left' }}>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Navigate to 'My Games'. Select a game, then click on a tag. This will start ranking the game against all
                others that are relevant.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Navigate to 'Tier Lists' and find a genre. Select 'Rank Games' and you will be able to rate all
                relevant games you own for that category.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                From the Main Menu, 'Rank Games'  will serve you the most relevant categories of games you have played the most.
              </Typography>
            </li>
          </Box>
          <Typography variant="h6" color="warning" gutterBottom sx={{ textAlign: 'left' }}>
            This site is in early BETA testing. Expect bugs. Database could be wiped at any time without notice!
          </Typography>
        </>
      ),
    },
  ];

  return <TabbedPageLayout topCardProps={topCardProps} tabs={tabs} />;
};

export default AboutPage;
