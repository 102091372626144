import React from 'react';
import FaceOffTableV2 from './Tables/FaceOffTablev2';

const headCells = [
  {
    id: 'relevance_rank',
    label: 'Rank',
    type: 'text', // Default type for numeric/text values
    numeric: true,
  },
  {
    id: 'competitor_name',
    label: 'Competitor ID',
    type: 'link', // Specifies that this column is a link
    numeric: false,
    properties: {
      page_name: 'competitor/', // Base URL for the link
      link_data: 'competitor', // Field to append to the link
    },
  },
  {
    id: 'competitor_relevance_score',
    label: 'Play Time Rank (Top %)',
    type: 'text', // Default type
    numeric: true,
    hideMobile:true
  },
  {
    id: 'criterion_relevance_score',
    label: 'Criterion Relevance (Top %)',
    type: 'text', // Default type
    numeric: true,
    hideMobile:true
  },
  {
    id: 'elo_difference_score',
    label: 'ELO Difference',
    type: 'text', // Default type
    numeric: true,
    hideMobile:true
  },
  {
    id: 'previous_ranked_count_score',
    label: "Times You've Recently Ranked",
    type: 'text', // Default type
    numeric: true,
    hideMobile:true
  },
  {
    id: 'relevance_total_score',
    label: 'Total Relevancy Score',
    type: 'text', // Default type
    numeric: true,
    hideMobile:true
  },
  {
    id: 'hidden',
    label: 'Hidden?',
    type: 'boolean', // Column to display a boolean value
    numeric: false,
  },
  {
    id: 'on_cooldown',
    label: 'Recently Ranked',
    type: 'boolean', // Column to display a boolean value
    numeric: false,
  },
  {
    id: 'exclude',
    label: 'Exclude',
    type: 'boolean', // Column to display a boolean value
    numeric: false,
  },
];

const ChallengerMatchesTable = ({ rows }) => {
  return (
    <div>
      <FaceOffTableV2 rows={rows} headCells={headCells} />
    </div>
  );
};

export default ChallengerMatchesTable;
