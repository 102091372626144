import React, { useEffect, useState, useRef } from 'react';
import { useUser } from '../UserContext';
import { useParams } from 'react-router-dom';
import TabbedPageLayout from '../components/TabbedPageLayout';
import CriterionCard from '../components/CriterionCard';
import TierList from '../components/TierList';
import AITester from '../components/AITester';
import SEO from '../components/SEO';
import axios from 'axios';
import { CircularProgress, Alert, Container } from '@mui/material';
import ShareButtons from '../components/chips/ShareButtons';
import GenericChip from '../components/chips/GenericChip';
import {  Face, ListAlt, BarChart, Build } from '@mui/icons-material';
import DownloadImageChip from '../components/chips/DownloadImageChip';
import TierListStatisticsTable from '../components/Tables/TierListStatisticsTable';

const LeaderboardPage = () => {
  const [criterionData, setCriterionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [leaderboardId, setLeaderboardId] = useState(null);
  const { user } = useUser();
  const { criterion_name, id } = useParams();
  const tierListRef = useRef(null);

  useEffect(() => {
    const fetchLeaderboardDetails = async () => {
      try {
        let endpoint = '';
        if (id) {
          setLeaderboardId(id);
          endpoint = `${process.env.REACT_APP_SERVER_URL}/GetLeaderboard/${id}`;
        } else if (criterion_name) {
          endpoint = `${process.env.REACT_APP_SERVER_URL}/GetLeaderboardByCriterionName/${criterion_name}`;
        }
        if (!endpoint) throw new Error('Invalid URL parameters.');

        const response = await axios.get(endpoint);
        setCriterionData(response.data);
        setLeaderboardId(response.data.corelation_id);
        console.log(response.data);
      } catch (err) {
        setError('Error fetching leaderboard data.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboardDetails();
  }, [criterion_name, id]);

  if (loading) {
    return (
      <>
        <SEO title="Loading Leaderboard..." description="Loading leaderboard data. Please wait..." />
        <CircularProgress />
      </>
    );
  }

  if (error) {
    return (
      <>
        <SEO title="Error - Leaderboard" description="An error occurred while loading the leaderboard data." />
        <Alert severity="error">{error}</Alert>
      </>
    );
  }


  const customTopCard = (
    <CriterionCard
      title={criterionData.name}
      name={criterionData.criterion_name}
      description={criterionData.leaderboard_description}
      avatar_url={criterionData.large_image_url}
      criterionStyles={{
        emoji: criterionData.criterion_emoji || '',
        transition_1_color: criterionData.transition_color_1 || '#4a4a4a',
        transition_2_color: criterionData.transition_color_2 || '#141414',
        font_color: criterionData.font_color || '#FFFFFF',
      }}
      tooltipText="This is a tooltip for the leaderboard"
    />
  );

  const tabs = [
    {
      label: 'Tier List',
      icon: <ListAlt />,
      title: `${criterionData.name}`,
      subtitle: `This is a live Tier List generated based of user votes on Steam Games with the User Tag '${criterionData.criterion_name}' on the Steam Platform. If you think games are misplaced or missing, you can vote to change their position by selecting 'Rank Games'. To see your results in isolation, select 'See Your Tierlist'.`,
      content: (
        
        <>
           

        <Container id="tier-list-container" ref={tierListRef} sx={{ padding: 0 }}>
            <TierList criterion={criterionData.criterion_name} />
        </Container>
        <ShareButtons title={criterionData.criterion_name} url={`${process.env.REACT_APP_SERVER_URL}/share-tier-list/${leaderboardId}`} displayName={"Share Tier List"} />
            {
              user && (
                <GenericChip label="See Your Tierlist" url={`/UserTierList/criterion_name/${criterionData.criterion_name}`} icon={<Face />} />
              )
            }
            <DownloadImageChip elementId="tier-list-container" tierListName={criterionData.name} url={window.location.href} criterionName={criterionData.criterion_name} />
        </>
      ),
    },
    {
      label: 'Statistics',
      icon: <BarChart />,
      title: 'Statistics',
      subtitle: 'More detailed statistics about the tier list.',
      content: (
        <TierListStatisticsTable
          criterion_name={criterionData.criterion_name}
          leaderboardId={leaderboardId}
        />
      ),
    },
    user?.accountType === 'admin' && {
      label: 'AI Tester',
      icon: <Build />,
      title: 'AI Tester',
      content: (
        <>
          <AITester
            criterion={criterionData.criterion_name}
            competitorDescriptor="Video Game"
            criterionDescriptor="Category"
          />
        </>
      ),
    },
  ].filter(Boolean);

  return (
    <>
      <SEO
        title={`Tier List - ${criterionData?.criterion_name || 'Tier List'}`}
        description={`Explore the Tier List for the best ${criterionData?.criterion_name}.`}
        url={window.location.href}
      />
      <TabbedPageLayout customTopCard={customTopCard} tabs={tabs} />
    </>
  );
};

export default LeaderboardPage;
