import { useUser } from '../UserContext';
import TabbedPageLayout from '../components/TabbedPageLayout';
import Login from './Login';
import FaceOffImageList from '../components/FaceOffImageList';
import FaceOffDataTable from '../components/Tables/FaceOffDataTable';
import { CheckCircle, HourglassEmpty, BarChart } from '@mui/icons-material';

const MyTierListsPage = () => {
  const { user } = useUser();

  if (!user) {
    return <Login />;
  }


  const tabs = [
    {
      label: 'Complete',
      icon: <CheckCircle />,
      title: 'Completed Tier Lists',
      subtitle: 'Your Tier Lists where all games have been ranked.',
      content:  <FaceOffImageList
      dataSource={`${process.env.REACT_APP_SERVER_URL}/GetCompletedLeadboards/${user.id}`}
      linkTo={`UserTierList/id/`}
      itemDescriptor="Tier List"
      metricDescriptor="Ranked Games"
      metricField='seeded_competitors'
      sortDirection='desc'
    />,
    },
    {
      label: 'Incomplete',
      icon: <HourglassEmpty />,
      title: 'Incomplete Tier Lists',
      subtitle: 'Your Tier Lists with unranked games. Tier lists with less than 5 games are not shown.',
      content:  <FaceOffImageList
      dataSource={`${process.env.REACT_APP_SERVER_URL}/GetIncompleteLeadboards/${user.id}`}
      linkTo={`UserTierList/id/`}
      itemDescriptor="Tier List"
      metricDescriptor="Unranked Games"
      metricField='unseeded_competitors'
      sortDirection='asc'
    />,
    },
    {
      label: 'STATS',
      icon: <BarChart />,
      title: 'Tier List Stats',
      subtitle: 'View in depth statistics for your tier lists.',
      content:   <FaceOffDataTable
      endpoint={`${process.env.REACT_APP_SERVER_URL}/GetLeadboards/${user.id}`}
      headCells={[
        {
          id: 'criterion_emoji',
          label: 'Emoji',
          type: 'text', // For FaceOffTableV2 compatibility
          numeric: false,
        },
        {
          id: 'name',
          label: 'Game',
          type: 'link', // Specifies that this column is a link
          numeric: false,
          properties: {
            page_name: 'TierList/id/',
            link_data: 'corelation_id',
          },
        },
        {
          id: 'seeded_competitors',
          label: '# Ranked',
          type: 'numeric', // Numeric field
          numeric: true,
          hideMobile: true,
        },
        {
          id: 'unseeded_competitors',
          label: '# Unranked',
          type: 'numeric', // Numeric field
          numeric: true,
          hideMobile: true,
        },
        {
          id: 'games_ranked_percent',
          label: '% Games Ranked',
          type: 'numeric', // Numeric field
          numeric: true,
        },
        {
          id: 'faceoff',
          label: '',
          type: 'faceoff',
          numeric: false,
          properties: { criterion: 'criterion_id' },
        },
        {
          id: 'share',
          label: '',
          type: 'share',
          numeric: false,
          properties: {
            criterion: 'criterion_id'
          },
        },
      ]}
    defaultSortField="relevancy_metric"
    defaultSortOrder="desc"
    />
    },
  ];

  return (
    <TabbedPageLayout
      topCardProps={{
        title: 'My Tier Lists',
        body: ['See your games ranked by tier'],
      }}
      tabs={tabs}
    />
  );
};

export default MyTierListsPage;