import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Search, ArrowUpward, ArrowDownward, HorizontalRule } from '@mui/icons-material';
import SocialShare from '../chips/SocialShare';
import { CheckCircle as TickIcon, Cancel as CrossIcon } from '@mui/icons-material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import RankChip from '../chips/RankChip';
import { useMediaQuery } from '@mui/material';

// Define the mobile breakpoint as a constant
const MOBILE_BREAKPOINT = 800;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const SwipeChip = () => (
  <Box
    sx={{
      display: { xs: 'flex', sm: 'none' }, // Visible only on xs screens
      justifyContent: 'flex-end',
      mb: 0.1, // Margin below the chip
    }}
  >
    <Chip
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <ArrowBack sx={{ fontSize: 16 }} />
          <span>Swipe</span>
          <ArrowForward sx={{ fontSize: 16 }} />
        </Box>
      }
      sx={{
        backgroundColor: 'black',
        color: 'white',
        fontSize: 12,
        height: '24px',
      }}
    />
  </Box>
);

function FaceOffTableV2({ order, orderBy, headCells, onRequestSort }) {
  const isMobile = window.innerWidth < MOBILE_BREAKPOINT; // Use the constant here
  const visibleHeadCells = isMobile
    ? headCells.filter((headCell) => !headCell.hideMobile)
    : headCells;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {visibleHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: 'black',
              color: 'white',
              '& .MuiTableSortLabel-root': { color: 'white' },
              '& .MuiTableSortLabel-root.Mui-active': { color: 'white' },
              '& .MuiTableSortLabel-root:hover': { color: 'lightgray' },
              '& .MuiTableSortLabel-icon': { color: 'white !important' },
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

FaceOffTableV2.propTypes = {
  headCells: PropTypes.array.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

export default function EnhancedTable({
  rows,
  headCells,
  defaultSortField = headCells[0]?.id,
  defaultSortOrder = 'asc',
  enableSearch = true,
}) {
  const [order, setOrder] = React.useState(defaultSortOrder);
  const [orderBy, setOrderBy] = React.useState(defaultSortField);
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState('');
  const isMobile = window.innerWidth < MOBILE_BREAKPOINT; // Use the constant here

  const isXs = useMediaQuery('(max-width:600px)'); // Custom media query for xs (600px and below)
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default state is 10

  useEffect(() => {
    if (isXs) {
      setRowsPerPage(5); // Set rowsPerPage to 5 for xs displays
    } else {
      setRowsPerPage(10); // Reset to default when not xs
    }
  }, [isXs]);

  const visibleHeadCells = isMobile
    ? headCells.filter((headCell) => !headCell.hideMobile)
    : headCells;

  const filteredRows = React.useMemo(() => {
    if (!searchTerm) return rows;
    return rows.filter((row) =>
      visibleHeadCells.some((headCell) => {
        const cellValue = row[headCell.id];
        return cellValue && cellValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
  }, [rows, searchTerm, visibleHeadCells]);

  const sortedRows = React.useMemo(
    () => [...filteredRows].sort(getComparator(order, orderBy)),
    [filteredRows, order, orderBy]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const rowCount = filteredRows.length;
  const visibleRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  /**
   * Renders the content of a table cell based on its type.
   * @param {Object} row - The data for the current row.
   * @param {Object} headCell - The definition of the current cell.
   * @returns {JSX.Element} The content to be rendered inside the table cell.
   */
  const renderCellContent = (row, headCell) => {
    const prefixEmoji = headCell.properties?.defaultEmoji || ''; // Fetch emoji if defined
  
    if (headCell.hideMobile && window.innerWidth < MOBILE_BREAKPOINT) return null;
  
    switch (headCell.type) {
      case 'faceoff':
        return (
          <div>
            {prefixEmoji}
            <RankChip competitorId={row[headCell.properties.competitor]} criterionName={row[headCell.properties.criterion]} />
          </div>
        );
      case 'tier': {
        const tierValue = row[headCell.id];
        const tierClass = `tier-${tierValue}`;
        return (
          <div className={`tier ${tierClass}`}>
            <div className="tier-header">
              {prefixEmoji} {tierValue}
            </div>
          </div>
        );
      }
      case 'boolean':
        return row[headCell.id] ? (
          <div>
            {prefixEmoji}
            <TickIcon color="success" />
          </div>
        ) : (
          <div>
            {prefixEmoji}
            <CrossIcon color="error" />
          </div>
        );
      case 'image':
        return row[headCell.id] ? (
          <div>
            {prefixEmoji}
            <img
              src={row[headCell.id]}
              alt={row[headCell.id]}
              style={{ width: 'auto', maxHeight: '50px', borderRadius: '8px' }}
            />
          </div>
        ) : (
          `${prefixEmoji}—`
        );
      case 'link':
        return row[headCell.properties.link_data] ? (
          <div>
            {prefixEmoji}
            <Link
              to={`/${headCell.properties.page_name}${row[headCell.properties.link_data]}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {row[headCell.id] || '—'}
            </Link>
          </div>
        ) : (
          `${prefixEmoji}—`
        );
      case 'share': {
        const props = headCell.properties;
        return (
          <div>
            {prefixEmoji}
            <SocialShare
              primaryCompetitorId={row[props.primaryCompetitorId]}
              primaryCompetitorName={row[props.primaryCompetitorName]}
              primaryCompetitorImage={row[props.primaryCompetitorImage]}
              challengerCompetitorId={row[props.challengerCompetitorId]}
              challengerCompetitorName={row[props.challengerCompetitorName]}
              criterion={row[props.criterion]}
              displayName="Share"
            />
          </div>
        );
      }
      case 'trend': {
        const trendValue = row[headCell.properties.trendField];
        let trendSymbol = <HorizontalRule sx={{ verticalAlign: 'bottom' }} />; // Default neutral symbol

        if (trendValue > 0) {
          trendSymbol = <ArrowUpward color="success" sx={{ verticalAlign: 'bottom' }} />; // Positive symbol
        } else if (trendValue < 0) {
          trendSymbol = <ArrowDownward color="error" sx={{ verticalAlign: 'bottom' }} />; // Negative symbol
        }

        return (
          <div>
            {trendSymbol} {row[headCell.id]}
          </div>
        );
      }
      default:
        return row[headCell.id] !== undefined && row[headCell.id] !== null
          ? `${prefixEmoji}${row[headCell.id]}`
          : `${prefixEmoji}—`;
    }
  };
  
  

  return (
<Box sx={{ width: '100%' }}>
  {/* Search Bar */}
  {enableSearch && (
    <TextField
      type="search"
      label="Search"
      value={searchTerm}
      onChange={handleSearchChange}
      fullWidth
      margin="normal"
      size="small"
      sx={{ m: 1, width: '80%' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  )}

  {/* Swipe Chip */}
  <Box
    sx={{
      display: { xs: 'flex', sm: 'none' }, // Visible only on xs screens
      justifyContent: 'flex-end',
      mb: 0.1 // Margin below the chip
    }}
  >
    <SwipeChip/>
  </Box>

  {/* Table */}
  <TableContainer>
    <Table sx={{ minWidth: 300 }} aria-labelledby="tableTitle">
      <FaceOffTableV2
        order={order}
        orderBy={orderBy}
        headCells={visibleHeadCells}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        {visibleRows.length > 0 ? (
          visibleRows.map((row, index) => (
            <TableRow hover tabIndex={-1} key={index}>
              {visibleHeadCells.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'}>
                  {renderCellContent(row, headCell)}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={visibleHeadCells.length}>
              <Typography align="center" variant="body1">
                No results found.
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>

  {/* Pagination */}
  <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={rowCount}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
    <Box
    sx={{
      display: { xs: 'flex', sm: 'none' }, // Visible only on xs screens
      justifyContent: 'flex-end',
      mb: 0.1 // Margin below the chip
    }}
  >
    <SwipeChip/>
  </Box>
</Box>
  );
}
