import React, { useEffect, useState } from 'react';
import { useUser } from '../UserContext';
import { useParams } from 'react-router-dom';
import TabbedPageLayout from '../components/TabbedPageLayout';
import CriterionCard from '../components/CriterionCard';
import TierList from '../components/TierList';
import SEO from '../components/SEO';
import axios from 'axios';
import { CircularProgress, Alert, Typography, Container } from '@mui/material';
import Login from './Login';
import FaceOffImageList from '../components/FaceOffImageList';
import ShareButtons from '../components/chips/ShareButtons';
import GenericChip from '../components/chips/GenericChip';
import { Public } from '@mui/icons-material';
import DownloadImageChip from '../components/chips/DownloadImageChip';
import { ListAlt, BarChart } from '@mui/icons-material';
import TierListStatisticsTable from '../components/Tables/TierListStatisticsTable';

const TierListPage = () => {
  const [criterionData, setCriterionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { user } = useUser();
  const { criterion_name, id } = useParams();
  const [leaderboardId, setLeaderboardId] = useState(null);
  const tierListRef = React.createRef();

  useEffect(() => {
    const fetchLeaderboardDetails = async () => {
      try {
        let endpoint = '';
        let withCredentials = false;
        if (id) {
          endpoint = `${process.env.REACT_APP_SERVER_URL}/GetLeaderboard/${id}`;
        } else if (criterion_name && user) {
          endpoint = `${process.env.REACT_APP_SERVER_URL}/GetMyLeaderboard/${criterion_name}`;
          withCredentials = true;
        }
        const response = await axios.get(endpoint, { withCredentials: withCredentials });
        console.log('Leaderboard data:', response.data);
        setCriterionData(response.data);
        setLeaderboardId(response.data.corelation_id);
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          setError(`Error fetching Tier List data: ${err.response.data.error}`);
        } else {
          setError('Error fetching Tier List data.' + err.message);
        }
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (id || (criterion_name && user)) {
      fetchLeaderboardDetails();
    }
  }, [criterion_name, id, user]);

  useEffect(() => {
    if (leaderboardId) {
      console.log('leaderboardId set:', leaderboardId);
    }
  }, [leaderboardId]);

  if (loading) {
    return (
      <>
        <SEO title="Loading Tier List..." description="Loading tier list data. Please wait..." />
        <CircularProgress />
      </>
    );
  }
  if (error) {
    return (
      <>
        <SEO title="Error - Tier List" description="An error occurred while loading the tier list data." />
        <Alert severity="error">{error}</Alert>
      </>
    );
  }

  const customTopCard = (
    <CriterionCard
      title={criterionData.name}
      name={criterionData.criterion_name}
      description={criterionData.leaderboard_description}
      avatar_url={criterionData.large_image_url}
      criterionStyles={{
        emoji: criterionData.criterion_emoji || '',
        transition_1_color: criterionData.transition_color_1 || '#4a4a4a',
        transition_2_color: criterionData.transition_color_2 || '#141414',
        font_color: criterionData.font_color || '#FFFFFF',
      }}
      tooltipText="This is a tooltip for the leaderboard"
    />
  );
const tabs = [
  {
    label: 'Tier List',
    icon: <ListAlt />,
    title: `${criterionData.name} Tier List`,
    subtitle: `This is a live Tier List generated based of your votes on Steam Games with the User Tag '${criterionData.criterion_name}' on the Steam Platform. If you think games are misplaced or missing, you can vote to change their position by selecting 'Rank Games'. To see a crowd sourced list , select 'See Global Tierlist'.`,
    content: (
      <>
        <>
          <Container id="tier-list-container" ref={tierListRef} sx={{ padding: 0 }}>
            <TierList leaderboardId={leaderboardId} />
          </Container>
          {user && leaderboardId ? (
              <>
                <Typography variant="h5" component="div" sx={{ fontWeight: '600' }}>
                  Your Unseeded Games
                </Typography>
                <Typography variant="body1" component="div" sx={{ color: '#555', fontStyle: 'italic' }}>
                  These games are yet to be added to your tier list as they have not been ranked enough times or you have hidden them.
                </Typography>
                <FaceOffImageList
                  dataSource={`${process.env.REACT_APP_SERVER_URL}/GetMyUnseededCompetitorsForLeaderboard/${leaderboardId}`}
                  linkTo="competitor/"
                  itemDescriptor="game"
                  metricDescriptor=" elo"
                  showSearch={false}
                  hiddenField={"is_hidden"}
                />
              </>
            ) : (
              <Login />
            )}
          <ShareButtons title={criterionData.criterion_name} url={`${process.env.REACT_APP_SERVER_URL}/share-tier-list/${leaderboardId}`} displayName={"Share Tier List"} />
          <GenericChip label="See Global Tierlist" url={`/TierList/criterion_name/${criterionData.criterion_name}`} icon={<Public />} />
          <DownloadImageChip elementId="tier-list-container" tierListName={criterionData.name} url={window.location.href} criterionName={criterionData.criterion_name} qrCodeTitle='Create your own List!'/>
        </>
      </>
    ),
  },
    {
      label: 'Statistics',
      icon: <BarChart />,
      title: 'Statistics',
      subtitle: 'More detailed statistics about the tier list.',
      content: (
        <TierListStatisticsTable
          criterion_name={criterionData.criterion_name}
          leaderboardId={leaderboardId}
        />
      ),
    },
  ];

  return (
    <>
      <SEO
        title={`Tier List - ${criterionData?.criterion_name || 'Tier List'}`}
        description={`Explore the tier list for the best ${criterionData?.criterion_name}.`}
        url={window.location.href}
      />
      <TabbedPageLayout customTopCard={customTopCard} tabs={tabs} />
     
    </>
  );
};

export default TierListPage;